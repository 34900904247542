<template lang="pug">
  .tab-main-container
    .headline ステータスバッジ一覧
    #main-content
      .row
        .col-6.col-md-2(v-for="(item,index) in badgeIconPathList" :value="item")
          //-ユーザーのレベル以下のステータスバッジを表示する
          .card(v-if="item.level <= userLevel")
            .card-body.text-center
              .card-title <b>Level {{item.level}}</b> ： {{item.name}}
              .badge
                img(:src="createImgPath(item.imgPath)")
</template>

<script>
export default {
  // データオブジェクト
  data() {
    return {
      userLevel: this.$parent.userLevel,
      badgeIconPathList:this.$parent.badgeIconPathList,
    }
  },
  watch: {
    '$parent.userLevel' (to, from) {
      this.userLevel = this.$parent.userLevel
    }
  },  
  created() {
  },
  // メソッド
  methods: {
    createImgPath(path){
      return this.getStatusIconPath(path)
    },
  },
  // 算出プロパティ
  computed: {
  },
  // コンポーネント
  components: {
  }
}
</script>

<style lang="stylus" scoped>
#main-content
  background-image linear-gradient(to top, #004170, #02497c, #035188, #065994, #0861a1)
  .row
    margin 0
    .col-6,.col-md-2
      padding 0.5rem
      .card
        border none
        width 100%
        .card-body
          margin 2px
          padding 0px
          .card-title
            font-size 0.8rem
            padding-top 1rem
            color #004170
            margin-bottom 0
          .badge
            width 70%
            height 70%
            img
              display flex
              justify-content center
              align-items center
              width 100%
              height auto
              border-radius 50%

//IEの場合
@media all and (-ms-high-contrast: none)
  .card
    height 180px
</style>
