var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-main-container" }, [
    _c("div", { staticClass: "headline" }, [_vm._v("ステータスバッジ一覧")]),
    _c("div", { attrs: { id: "main-content" } }, [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.badgeIconPathList, function(item, index) {
          return _c(
            "div",
            { staticClass: "col-6 col-md-2", attrs: { value: item } },
            [
              item.level <= _vm.userLevel
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body text-center" }, [
                      _c("div", { staticClass: "card-title" }, [
                        _c("b", [_vm._v("Level " + _vm._s(item.level))]),
                        _vm._v(" ： " + _vm._s(item.name))
                      ]),
                      _c("div", { staticClass: "badge" }, [
                        _c("img", {
                          attrs: { src: _vm.createImgPath(item.imgPath) }
                        })
                      ])
                    ])
                  ])
                : _vm._e()
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }